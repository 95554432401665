<template>
<div id="TopTenReader" class="TopTenReader">
    <div style="paddingLeft:10px;font-size:18px">10 อันดับนักอ่าน</div>
    <a-list itemLayout="horizontal" :dataSource="readers">
        <a-list-item slot="renderItem" slot-scope="item, index" class="noOfReader">
            <a-row style="width:100%">
                <a-col :span="6">
                    <img v-if="index+1==1" width="20" height="20" src="./../../assets/first.png" />
                    <img v-else-if="index+1==2" width="20" height="20" src="./../../assets/second.png" />
                    <img v-else-if="index+1==3" width="20" height="20" src="./../../assets/third.png" />
                    <div v-else class="text">{{index+1}}</div>
                </a-col>
                <a-col :span="18" style="textAlign:left" class="text">
                    <a-list-item-content>
                        <div class="displayname" slot="title">{{item.member_displayname}}</div>
                        <a slot="title" style="fontSize:13px;">{{Number((parseInt(item.member_gold_usage)/3).toFixed(0))}} ครั้ง</a>
                    </a-list-item-content>
                </a-col>
            </a-row>
        </a-list-item>
    </a-list>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapGetters
} from 'vuex'
export default {
    name: 'TopTenReader',
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    data() {
        return {
            readers: []
        }
    },
    async mounted() {
        const {
            data
        } = await axios.get('https://api2.novelrealm.com/module/home&submodule=top_user')
        this.readers = data
        setTimeout(() => {
            this.checkMode()
        }, 10);
    },
    methods: {
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('TopTenReader').classList.remove('darkmode')
            document.getElementsByClassName('text').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            document.getElementById('TopTenReader').classList.add('darkmode')
            document.getElementsByClassName('text').forEach(element => {
                element.classList.add('darkmodeText')
            });
        }
    }
}
</script>

<style scoped>
.TopTenReader {
    border-radius: 10px;
    background-color: #ffffff;
    width: auto;
    padding: 10px;
}

.noOfReader {
    font-size: 15px;
    text-align: center;
}

.darkmode {
    background-color: #35363A;
    color: #ffffff !important;
}

.darkmodeText {
    color: #ffffff !important;
}
.displayname{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
